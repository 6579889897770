import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>The 50/30/20 Rule</h2>
              <p>
                Are you having trouble saving money? Is your financial strategy
                blurry with no clear direction? Depending on your level of
                income and your type of lifestyle, saving money may be quite
                challenging. It takes a lot of willpower and discipline to spend
                within your means and save. Following the 50/30/20 budget rule
                is a great place to start and it will ensure you are using your
                money in a responsible manner.
                <br />
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h3> STEP 1: FIGURE OUT YOUR INCOME AFTER-TAX</h3>
              <br />
              <p>
                {' '}
                If you are an employee on a salary this is easy. You can look at
                your last payslip and check what you got paid after tax and
                Medicare payments etc. If you are self-employed, figure out how
                much you have been paying yourself over the last year, and
                deduct the business tax payable. Tax works quite different for
                businesses compared to employees, so speak to your accountant if
                you are unsure. If you believe you will earn more or less in the
                next year adjust accordingly.
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h3>
                STEP 2: HAVE A “NEEDS” LIMIT OF 50% OF YOUR AFTER-TAX INCOME
              </h3>
              <p>
                After you have your after tax income, go through your bank
                statements and figure out how much you spend on essentials.
                Things like groceries, rent/ mortgage, health insurance, car
                expenses and utilities. Now there needs to be some common sense
                when calculating this figure. Something such as clothing is
                absolutely necessary, but overspending on designer clothes and
                shoes is not. If more than 50% of your income is being spent on
                needs, then you may need to reconsider your situation. Moving to
                a cheaper suburb or purchasing a fuel efficient car with low
                maintenance costs are both good starting blocks to reduce
                ‘needs’ spending.
                <br />
                <br />
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>

          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h3>STEP 3: SPEND A MAXIMUM OF 30% ON “WANTS”</h3>
              <p>
                Before you start to think about all the money you can spend at
                the hair salon, eating at fancy restaurants and overseas
                holidays, just hold up for one second. “Wants” are anything that
                is not absolutely essential. Something such as your unlimited
                data mobile phone plan, a FOXTEL subscription or spoiling
                yourself with an occasional wine and cheese night would all come
                under wants. You don’t “need” all that data for your phone, a
                Pay TV service or a bottle of wine. But, it is nice to indulge
                within reason every so often, so 30% on “wants” is acceptable.
              </p>
              <br />
            </div>
          </Grid>
          <Grid>
            <div>
              <h3>STEP 4: SPEND 20% ON SAVINGS AND DEBT REPAYMENTS</h3>
              <p>
                At least 20% of your after-tax income should be spent on either
                paying down debts or saving money. However for clarity, things
                like paying off your credit card balance, mortgage loans and car
                loans are all considered “needs”. Any additional money spent
                over the minimum payment required falls into this category. For
                example if your monthly home loan payments are $1500, that would
                be considered a need. However, if you put another $500 towards
                paying off the loan then that part would be considered in this
                category. Saving money in the bank, putting it towards an
                emergency fund and additional superannuation payments are
                obviously included in this category as well, and should be
                maximised where possible. Use our budgeting software to see how
                you can maximise your ability to budget for you and your family.
                <br />
                <br />
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
