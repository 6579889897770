import React from 'react';
import { Link } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';

import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import Brands from '@sections/Brands';
import Team from '@sections/Team';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';
import BudgetInfoSection from '@sections/BudgetInfoSection';

const BudgetPage = () => (
  <Layout>
    <Navbar />
    <BudgetInfoSection />
    <Faq />
    <Footer />
  </Layout>
);

export default BudgetPage;
